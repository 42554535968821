import { Link, withPrefix } from "gatsby";
import React from "react";
import { trackClickEvents } from "../../utils";

import "./block.scss";

export interface BlockProps {
  title: string;
  name: string;
  description: string;
  url: string;
  stars: number;
  forks: number;
  icon: string;
  isLoading?: boolean;
}

const _trackRepoAnalytics = (name: string, type: "star" | "fork") => {
  if (type === "star") {
    trackClickEvents(`${name} Stars`);
  } else {
    trackClickEvents(`${name} Forks`);
  }
};

const Block: React.FC<BlockProps> = ({
  title,
  description,
  name,
  url,
  stars,
  forks,
  icon,
  isLoading,
}) => {
  return (
    <div className="block">
      <div className="icon">
        <img
          alt={name}
          src={(icon!="blog")?`https://www.loginradius.com/wp-content/themes/login-radius/images/open-source-page/${icon}.svg`:"images/blog.svg"}
        />
        <a
          href={`https://github.com/LoginRadius/${name}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => _trackRepoAnalytics(name, "star")}
        ></a>
      </div>
      <div className="text">
        <h4>{title}</h4>
        <p>{description}</p>
        <div className="additional-stats">
          <a
            href={`https://github.com/LoginRadius/${name}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => _trackRepoAnalytics(name, "star")}
          >
            <img src={withPrefix("/images/star.svg")} alt="star" />
            <span>{isLoading ? "-" : stars}</span>
          </a>

          <a
            href={`https://github.com/LoginRadius/${name}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => _trackRepoAnalytics(name, "star")}
          >
            <img src={withPrefix("/images/fork.svg")} alt="fork" />
            <span>{isLoading ? "-" : forks}</span>
          </a>
        </div>
        <p>
          <Link to={url}>Learn More</Link>
        </p>
      </div>
    </div>
  );
};

export default Block;
