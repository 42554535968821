import React, { useEffect, useState } from "react";
import Block from "../components/Block";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { trackClickEvents } from "../utils";

const projects = [
  [
    {
      title: "LoginRadius CLI",
      descriptiom:
        "The LoginRadius CLI provides all the usefull commands using which you can manage your LoginRadius Admin Console functionality",
      url: "/cli",
      name: "lr-cli",
      icon: "loginradius-cli",
    },
    {
      title: "LoginRadius Blog",
      descriptiom:
        "A collection of resources by Loginradius engineering team to share their practical learning, development challenges, open-source initiatives in dev, QA, Infra, or implementation.",
      url: "/blog",
      name: "engineering-portal",
      icon: "blog",
    },{
      title: "PS Softlist",
      descriptiom:
        "A power shell script to detect non-whitelisted installed software on windows machines.",
      url: "/ps-softlist",
      name: "ps-softlist",
      icon: "ps-softlist",
    },
  ],
  [
    {
      title: "LoginRadius React SDK",
      descriptiom:
        "The React SDK for implemented LoginRadius for react applications",
      url: "/loginradius-react",
      name: "loginradius-react",
      icon: "react",
    },
    {
      title: "IDX Auto Tester",
      descriptiom:
        "A test suite to do test automation of LoginRadius IDX implementation",
      url: "/idx-auto-tester",
      name: "idx-auto-tester",
      icon: "idx-tester",
    },
    {
      title: "Awesome Test Cases Collection",
      descriptiom:
        "This repository contains collection of test cases for beginners that need to be included while validating projects of different domains/technologies.",
      url: "/awesome-test-cases-collection",
      name: "awesome-test-cases-collection",
      icon: "awesome-test-collection",
    },
  ],
  [
    {
      title: "Business Email Validator",
      descriptiom:
        "Help you to eliminate free email services in signup process to capture more potential customer for your business.",
      url: "/business-email-validator",
      name: "business-email-validator",
      icon: "business-email-validator",
    },
    {
      title: "Egnature",
      descriptiom:
        "Egnature is an email signature generator tool, which is an open source and free to use.",
      url: "/egnature",
      name: "egnature",
      icon: "egnature",
    },
    {
      title: "Go SAML",
      descriptiom:
        "High Level API Implementation of SAML 2.0 (Currently Supported Identity Provider Implementation) Single Sign On.",
      url: "/go-saml",
      name: "go-saml",
      icon: "go-saml",
    },
  ],
];

const IndexPage = () => {
  const [isLoading, setLoading] = useState(true);
  const [repos, setRepos] = useState({});
  useEffect(() => {
    fetch("https://api.github.com/orgs/loginradius/repos?per_page=100", {
      method: "GET",
      headers: {
        Accept: "application/vnd.github.v3+json",
      },
    })
      .then((res) => res.json())
      .then((resp) => {
        let repository = {};
        for (const repo of resp) {
          repository[repo.name] = repo;
        }
        setRepos(repository);
        setLoading(false);
      });
  }, []);

  return (
    <>
    <SEO />
    <Layout>
      <section className="bg-dark-02">
        <div className="grid-100">
          <div className="title">
            <h1>Open Source Projects</h1>
            <p>
              We believe that all developers are part of one big family. To
              share what we’ve learned with other developers, we’ve listed some
              of our open source projects here. Please join us in developing
              these projects further—let’s see where we can take this!
            </p>
            <p className="btn-adjacent">
              <a
                className="btn-primary"
                href="https://www.loginradius.com/contact-us/"
                onClick={() => trackClickEvents("Contact Us")}
              >
                Contact Us
              </a>
              <a
                href="https://github.com/LoginRadius"
                target="_blank"
                className="btn-secondary"
                rel="noopener noreferrer"
                onClick={() => trackClickEvents("LoginRadius Github")}
              >
                LoginRadius GitHub
              </a>
            </p>
          </div>
        </div>
      </section>
      {/* Hacktober fest */}
      {/* <section className="bg-light-01">
        <div>
          <div className="title">
            <div className="banner">
              <img
                src={withPrefix("/images/hactoberfest-banner.svg")}
                alt={"hacktoberfest banner"}
              />
            </div>

            <div className="padding-top--md ">
              <h2>Hacktoberfest 2021 has arrived!</h2>
              <h3>Are you ready to contribute and win some awesome swag?</h3>
              <p>
                Hacktoberfest is about making meaningful contributions to the{" "}
                <a href="https://github.com/LoginRadius" target="_blank">
                  LoginRadius open source projects
                </a>
                . Sign up for Hacktoberfest 2021 by clicking this{" "}
                <a
                  href="https://hacktoberfest.digitalocean.com/"
                  target="_blank"
                >
                  link
                </a>{" "}
                Then, read about{" "}
                <a
                  href="https://www.loginradius.com/blog/async/hacktoberfest-2021/"
                  target="_blank"
                >
                  how to participate
                </a>{" "}
                in a meaningful way.
              </p>
            </div>
          </div>
        </div>
      </section> */}
      {/* Hacktober fest */}
      <section className="bg-light-01 text-center">
        {projects.map((proj, index) => (
          <div
            key={`grid-${index}`}
            className="grid-33 nutshell margin-top--lg"
          >
            {proj.map((p, i) => (
              <Block
                key={`block-${i}`}
                title={p.title}
                description={p.descriptiom}
                name={p.name}
                url={p.url}
                stars={repos[p.name] ? repos[p.name].stargazers_count : 0}
                forks={repos[p.name] ? repos[p.name].forks_count : 0}
                icon={p.icon}
                isLoading={isLoading}
              />
            ))}
          </div>
        ))}
      </section>
    </Layout>
    </>
  );
};

const FALLBACK = (
  <div id="preloader" className="fullscreen">
    <div id="status">
      <div className="spinner">
        <div className="double-bounce1" />
        <div className="double-bounce2" />
      </div>
    </div>
  </div>
);

export default IndexPage;
